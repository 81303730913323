import * as React from "react";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { TopCitiesProps } from "components/flexComponents/TopCities/typings";
import { UitkHeading } from "@egds/react-core/text";
import IsomorphicCarousel from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { useLocalization } from "@shared-ui/localization-context";
import { TopCityCard } from "components/flexComponents/TopCities/views/TopCitiesCarousel/TopCityCard";
import {
  TopCitiesFlexModuleCarouselResult,
  TopCityCarouselCard,
} from "typings/microserviceModels/top-cities-flex-module";
import { UitkSpacing } from "@egds/react-core/spacing";

const TopCitiesCarousel = withStores(
  "flexModuleModelStore",
  "context",
  "analytics"
)(
  observer((props: TopCitiesProps) => {
    const { flexModuleModelStore, templateComponent } = props;
    const {
      metadata: { id },
    } = templateComponent;
    const keyHelper = new ItemKeyHelper("top-cities-carousel");
    const model = flexModuleModelStore.getModel(id) as TopCitiesFlexModuleCarouselResult;

    if (!model) {
      return null;
    }

    const { title, topCities } = model;
    const { formatText } = useLocalization();

    return (
      <div className="TopCitiesCarousel" data-testid="TopCitiesCarousel">
        {title && (
          <UitkSpacing margin={{ blockend: "four" }}>
            <UitkHeading size={4} tag="h3">
              {title}
            </UitkHeading>
          </UitkSpacing>
        )}
        <UitkSpacing margin={{ blockend: "four" }}>
          <IsomorphicCarousel
            carouselName="Top cities"
            buttonText={{
              nextButton: formatText("carousel.item.next"),
              prevButton: formatText("carousel.item.prev"),
            }}
            itemsVisible={{ lg: 3, md: 2, sm: 1 }}
            pageBy={1}
            peek
          >
            {topCities.map((topCity: TopCityCarouselCard) => (
              <TopCityCard
                key={keyHelper.next()}
                topCityName={topCity.name}
                imageUrl={topCity.images[0].url}
                shortDescription={topCity.shortDescription}
                link={topCity.link}
                callToActionLink={topCity.callToActionLink}
                callToActionLocKey={topCity.callToActionLocKey}
              />
            ))}
          </IsomorphicCarousel>
        </UitkSpacing>
      </div>
    );
  })
);

export default TopCitiesCarousel;
