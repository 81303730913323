import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkImage, UitkFigure } from "@egds/react-core/images";
import { UitkScrim } from "@egds/react-core/scrim";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkText, UitkSubheading } from "@egds/react-core/text";
import { UitkCard, UitkCardLink } from "@egds/react-core/cards";

import ProductIcon from "./ProductIcon";
import ProductDateSupportText from "./ProductDateSupportText";
import ProductPriceSupportText from "./ProductPriceSupportText";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { FullBleedImageCityCardProps } from "../../../typings";

const FullBleedImageCityCard = (props: FullBleedImageCityCardProps) => {
  const { city, index, pageType, hideProductIcon } = props;
  const { name: cityName, link, deal } = city;
  const mainImage = city.images[0];
  const { formatText } = useLocalization();

  return (
    <UitkCard>
      <UitkFigure ratio={UitkFigure.AspectRatio.R16_9}>
        <UitkImage src={mainImage.url} alt={mainImage.alt} placeholderImage lazyLoading="lazy" />
        {!hideProductIcon && <ProductIcon index={index} cardType="full-bleed-image" />}
        <UitkScrim>
          <UitkLayoutFlex justifyContent="space-between" alignItems="end">
            <UitkLayoutFlex direction="column">
              {/* using h tag instead of subheading p for a11y screen reader capabilites of elemenst inside of the carousel*/}
              <UitkSubheading tag="h4">{cityName}</UitkSubheading>
              <ProductDateSupportText deal={deal} />
            </UitkLayoutFlex>
            {deal && (
              <UitkLayoutFlex direction="column">
                <UitkText size={300} align="right">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: formatText("topCities.productPrice", deal.priceFormatted),
                    }}
                  />
                </UitkText>
                <ProductPriceSupportText />
              </UitkLayoutFlex>
            )}
          </UitkLayoutFlex>
        </UitkScrim>
      </UitkFigure>

      <UitkCardLink>
        <TrackedLink
          moduleName="topCities"
          rfrr={pageType}
          href={link}
          data-testid={`top-cities-full-bleed-image-${index}`}
        >
          {cityName}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export default FullBleedImageCityCard;
