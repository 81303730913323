import * as React from "react";
import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { TopCitiesProps } from "../../typings";
import { UitkFigure, UitkImage } from "@egds/react-core/images";
import { UitkSecondaryButton } from "@egds/react-core/button";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { observer } from "mobx-react";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { LocalizedText } from "bernie-l10n";
import {
  CityDiscovery,
  PointOfInterest,
  TopCitiesFlexModuleDestinationDiscoveryResult,
} from "typings/microserviceModels/top-cities-flex-module";
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { useLocalization } from "@shared-ui/localization-context";
import { AttributionText, getAttributionAltText } from "components/shared/AttributionText/AttributionText";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkList, UitkListItem } from "@egds/react-core/list";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";
import { BlossomImage, cropResizeSrc } from "src/components/shared/BlossomImage/BlossomImage";
import { withStores } from "stores";
import LazyLoad from "react-lazyload";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const ButtonTracked = FlexClickTracker(UitkSecondaryButton);

interface MessageType {
  1: React.JSX.Element;
  2: React.JSX.Element;
  3: React.JSX.Element;
}

export const TopCitiesDestinationDiscovery = withStores(
  "flexModuleModelStore",
  "analytics"
)(
  observer((props: TopCitiesProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;
    const {
      metadata: { id },
      config: { fmTitleId, view },
      type: moduleName = "",
    } = templateComponent;
    const fmId = getFmId(templateComponent);
    const keyHelper = new ItemKeyHelper("top-cities");
    const model = flexModuleModelStore.getModel(id) as TopCitiesFlexModuleDestinationDiscoveryResult | null;

    // Return null if not DD view since Hotel-Destination pages also have this module and we don't yet support it
    if (!model || !model.topCities || model.empty || view !== "destination-discovery") {
      return null;
    }

    return (
      <UitkSpacing margin={{ blockend: "six" }}>
        <div className="TopCitiesDestinationDiscovery" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
          <UitkSpacing margin={{ blockend: "three" }}>
            <div>
              {Boolean(context.searchContext.location?.localizedName) && (
                <UitkHeading size={5} tag="h3">
                  <LocalizedText message="topCities.title" data={[context.searchContext.location.localizedName]} />
                </UitkHeading>
              )}
            </div>
          </UitkSpacing>
          <div className="topCitiesContainer">
            {model.topCities.map((city, cityIndex) => (
              <CityCard
                key={keyHelper.next()}
                city={city}
                index={cityIndex}
                context={context}
                moduleName={moduleName}
              />
            ))}
          </div>
        </div>
      </UitkSpacing>
    );
  })
);

export const CityCard = observer(
  (props: { city: CityDiscovery; index: number; context: ExtendedContextStore; moduleName: string }) => {
    const { city, context, moduleName } = props;
    const { name, shortDescription, exploreUrl, hotelUrl, affinity, images } = city;
    const poiKeyHelper = new ItemKeyHelper("top-cities-poi");
    const cityImageUrl = images?.[0]?.url;

    const image = images && images[0];
    const imageAttribution = image?.attribution;
    const attributionLicense = imageAttribution?.license;
    const { formatText } = useLocalization();

    const imageAlt = image?.alt || "";

    const source = image?.source;
    const origin = image?.origin || "";
    const attributionUrl = imageAttribution?.url;
    const attributionRequired = attributionLicense?.attributionRequired;
    const authorName = imageAttribution?.authorName;
    const authorUrl = imageAttribution?.authorUrl;
    const licenseSuite = attributionLicense?.suite;
    const licenseName = attributionLicense?.name;
    const licenseUrl = attributionLicense?.url;

    const attributionAltText = getAttributionAltText(
      source,
      origin,
      name,
      imageAlt,
      authorName,
      attributionLicense,
      attributionUrl
    );

    const affinities = (knownFor: string[]) => {
      if (!knownFor?.length) {
        return null;
      }

      const messageType: MessageType = {
        1: <LocalizedText message="topCities.knownFor1" data={[knownFor[0]]} />,
        2: <LocalizedText message="topCities.knownFor2" data={[knownFor[0], knownFor[1]]} />,
        3: <LocalizedText message="topCities.knownFor3" data={[knownFor[0], knownFor[1], knownFor[2]]} />,
      };

      return messageType[knownFor.length as keyof MessageType];
    };

    return (
      <UitkSpacing margin={{ inlinestart: "two" }}>
        <UitkCard className="topCitiesCard" border>
          <UitkFigure>
            <TrackedLink
              title={`Open ${city.name} page`}
              className="uitk-attribution-link"
              moduleName="topCities"
              href={city.exploreUrl}
              rfrr="topCities.exploreCity"
            >
              <LazyLoad placeholder={<UitkImage alt={attributionAltText} placeholderImage />} offset={250} once>
                <BlossomImage alt={attributionAltText} src={cityImageUrl} />
              </LazyLoad>
              <UitkScrim type={UitkScrimType.BOTTOM}>
                <div className={attributionRequired ? "topCities-hero-caption-container" : ""}>
                  <UitkText size={500} weight="bold" overflow="truncate">
                    {name}
                  </UitkText>
                  <UitkText size={300}>{affinities(affinity)}</UitkText>
                </div>
              </UitkScrim>
            </TrackedLink>
            <AttributionText
              source={source}
              origin={origin}
              attribUrl={attributionUrl}
              authorName={authorName}
              authorUrl={authorUrl}
              licenseSuite={licenseSuite}
              licenseName={licenseName}
              licenseUrl={licenseUrl}
            />
          </UitkFigure>

          <UitkCardContentSection className="first">
            <UitkSpacing margin={{ blockend: "four" }}>
              <UitkText size={300} overflow="truncate-3-lines">
                {shortDescription}
              </UitkText>
            </UitkSpacing>
            <div className="topCitiesSecondHalf">
              {Boolean(city?.pois?.length) && (
                <div>
                  <UitkHeading tag="h2" size={7}>
                    <LocalizedText message="topCities.reasonsToVisit" />
                  </UitkHeading>
                  <UitkList bullet="no-bullet">
                    <UitkSpacing margin={{ block: "four" }}>
                      <UitkLayoutFlex justifyContent="space-between" direction="column">
                        {city?.pois?.map((poi: PointOfInterest) => (
                          <UitkListItem key={poiKeyHelper.next()}>
                            {
                              // @ts-ignore
                              poi?.image?.[0]?.url ? (
                                <TrackedLink
                                  title={`Open ${poi.name}`}
                                  className="topCitiesPoiLink"
                                  moduleName="topCities"
                                  rfrr="cityPOI"
                                  href={poi.url}
                                >
                                  <CityPOI poi={poi} context={context} />
                                </TrackedLink>
                              ) : (
                                <CityPOI poi={poi} context={context} />
                              )
                            }
                          </UitkListItem>
                        ))}
                      </UitkLayoutFlex>
                    </UitkSpacing>
                  </UitkList>
                </div>
              )}
            </div>
          </UitkCardContentSection>
          <UitkSpacing margin={{ block: "four" }} padding={{ inline: "four" }}>
            <UitkLayoutFlex justifyContent="space-between">
              <ButtonTracked
                data-testid="exploreCityContainer"
                className="topCitiesCardButton"
                linkName="Explore city click event in the Top Cities"
                href={exploreUrl}
                tag="a"
                rfrr="exploreCity"
                moduleName={moduleName}
                aria-label={formatText("destinationDiscoveryMap.title.mobile", city.name)}
              >
                {formatText("topCities.exploreCity")}
              </ButtonTracked>
              <ButtonTracked
                data-testid="findHotelsContainer"
                className="topCitiesCardButton"
                linkName="Find Hotels click event in the Top Cities"
                href={hotelUrl}
                tag="a"
                rfrr="findHotels"
                moduleName={moduleName}
                aria-label={formatText("destinationCity.topCities.findHotels", city.name)}
              >
                {formatText("topCities.findHotels")}
              </ButtonTracked>
            </UitkLayoutFlex>
          </UitkSpacing>
        </UitkCard>
      </UitkSpacing>
    );
  }
);

export const CityPOI = (props: { poi: PointOfInterest; context: ExtendedContextStore }) => {
  const { poi } = props;

  return (
    <div className="topCitiesPoiCardMedia">
      <UitkSpacing margin={{ inlineend: "four" }}>
        <div className="topCitiesPoiImageContainer">
          {poi?.image?.url?.length ? (
            <UitkFigure
              src={cropResizeSrc(poi.image.url, { width: 100, height: 100 }, "low")}
              className="topCitiesPoiImageContainer"
            />
          ) : (
            <div className="TopCitiesPoiPlaceholder" />
          )}
        </div>
      </UitkSpacing>
      <UitkText size={300}> {poi.name} </UitkText>
    </div>
  );
};

export default TopCitiesDestinationDiscovery;
