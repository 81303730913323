import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkImage, UitkFigure } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { UitkSpacing } from "@egds/react-core/spacing";

import ProductDateSupportText from "./ProductDateSupportText";
import ProductPriceSupportText from "./ProductPriceSupportText";
import ProductTypeText from "./ProductTypeText";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { ImageLeftCardCityCardProps } from "../../../typings";

const ImageLeftCardCityCard = (props: ImageLeftCardCityCardProps) => {
  const { city } = props;
  const { name: cityName, link, deal } = city;
  const mainImage = city.images[0];
  const { formatText } = useLocalization();

  return (
    <UitkCard>
      <UitkLayoutFlex>
        <UitkLayoutFlexItem minWidth="third_width" shrink={0}>
          <div>
            <UitkFigure ratio={UitkFigure.AspectRatio.R1_1} imageFit="cover">
              <UitkImage src={mainImage.url} alt={mainImage.alt} placeholderImage lazyLoading="lazy" />
            </UitkFigure>
          </div>
        </UitkLayoutFlexItem>

        <UitkLayoutFlexItem grow={1}>
          <UitkSpacing padding={{ block: "three", inline: "three" }}>
            <UitkLayoutFlex direction="column" justifyContent="space-between">
              <UitkLayoutFlex direction="column">
                <UitkHeading size={5}>{cityName}</UitkHeading>
                <ProductTypeText />
              </UitkLayoutFlex>
              {deal && (
                <UitkLayoutFlex direction="column">
                  <UitkText align="right" size={300} weight="medium" theme="emphasis">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formatText("topCities.productPrice", deal.priceFormatted),
                      }}
                    />
                  </UitkText>
                  <ProductPriceSupportText />
                  <ProductDateSupportText deal={deal} />
                </UitkLayoutFlex>
              )}
            </UitkLayoutFlex>
          </UitkSpacing>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>

      <UitkCardLink>
        <TrackedLink
          moduleName="topCities"
          rfrr="hotelTravelGuideLink"
          href={link}
          data-testid={`top-cities-image-left-card-${cityName}`}
        >
          {cityName}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export default ImageLeftCardCityCard;
