import * as React from "react";
import { Viewport, ViewSmall, ViewLarge } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkFigure } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkSpacing } from "@egds/react-core/spacing";

import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

import { TopCitiesProps } from "../../typings";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { TopCitiesFlexModuleDefaultResult } from "typings/microserviceModels/top-cities-flex-module";
import { withStores } from "stores";
import { observer } from "mobx-react";

const TopCitiesDefault = withStores(
  "flexModuleModelStore",
  "analytics"
)(
  observer((props: TopCitiesProps) => {
    const { templateComponent, flexModuleModelStore, context } = props;

    const {
      metadata: { id },
    } = templateComponent;
    const model = flexModuleModelStore.getModel(id) as TopCitiesFlexModuleDefaultResult | null;
    if (!model) {
      return null;
    }
    const { title, topCities } = model;
    const { formatText } = useLocalization();
    const cityCardKeyHelper = new ItemKeyHelper("topCitiesCard");

    const cityCards = topCities.map((city, index) => (
      <UitkCard key={cityCardKeyHelper.next()}>
        <UitkFigure ratio={UitkFigure.AspectRatio.R16_9}>
          <BlossomImage src={city.imageUrl} alt={city.name} />
        </UitkFigure>
        <UitkCardContentSection>
          <UitkLayoutFlex justifyContent="space-between">
            <UitkText size={400} weight="bold">
              {city.name}
            </UitkText>
            {Boolean(city.price) && (
              <div
                dangerouslySetInnerHTML={{
                  __html: formatText("topCities.view.default.hotelPrice", city.price),
                }}
              />
            )}
          </UitkLayoutFlex>
        </UitkCardContentSection>

        <UitkCardLink>
          <TrackedLink
            moduleName="topCities"
            rfrr="hotelTravelGuideLink"
            href={city.link}
            data-testid={`top-cities-default-${index}`}
          >
            {city.name}
          </TrackedLink>
        </UitkCardLink>
      </UitkCard>
    ));

    return (
      <div id={id} className="TopCitiesDefault">
        <UitkHeading tag="h2" size={4}>
          {title || formatText("topCities.title", context.searchContext.location.localizedName)}
        </UitkHeading>

        <Viewport>
          <ViewSmall>
            <UitkSpacing margin={{ blockstart: "three" }}>
              <UitkLayoutGrid columns={1} space="three">
                {cityCards}
              </UitkLayoutGrid>
            </UitkSpacing>
          </ViewSmall>

          <ViewLarge>
            <UitkSpacing margin={{ blockstart: "three" }}>
              <UitkLayoutGrid columns={3} space="three">
                {cityCards}
              </UitkLayoutGrid>
            </UitkSpacing>
          </ViewLarge>
        </Viewport>
      </div>
    );
  })
);

export default TopCitiesDefault;
