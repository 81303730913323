import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import * as React from "react";
import LazyLoad from "react-lazyload";
import { LazyLoaderPlaceHolder } from "components/utility/LazyLoaderPlaceholderWrapper";
import { useLocalization } from "@shared-ui/localization-context";
import { BlossomImage } from "src/components/shared/BlossomImage/BlossomImage";
import { UitkLink } from "@egds/react-core/link";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkHeading, UitkText } from "@egds/react-core/text";

export const TopCityCard = ({
  topCityName,
  imageUrl,
  shortDescription,
  link,
  callToActionLink,
  callToActionLocKey,
}: {
  topCityName: string;
  imageUrl: string;
  shortDescription: string;
  link: string;
  callToActionLink: string;
  callToActionLocKey: string;
}) => {
  const { formatText } = useLocalization();

  return (
    <UitkLayoutGrid blockSize="full_size">
      <UitkLayoutGridItem>
        <UitkCard border>
          <UitkCardLink>
            <a href={link}>{topCityName}</a>
          </UitkCardLink>
          <UitkFigure ratio={UitkFigureAspectRatioType.R16_9}>
            <LazyLoad placeholder={<LazyLoaderPlaceHolder name={topCityName} />} offset={250}>
              <BlossomImage alt={topCityName} src={imageUrl} />
            </LazyLoad>
          </UitkFigure>

          <UitkCardContentSection>
            <UitkHeading tag="h4" size={6}>
              {topCityName}
            </UitkHeading>
            <UitkText size={300} spacing="two">
              {shortDescription}
            </UitkText>
          </UitkCardContentSection>

          {callToActionLink && callToActionLocKey && (
            <UitkCardContentSection padded>
              <UitkLink>
                <a href={callToActionLink}>{formatText(callToActionLocKey)}</a>
              </UitkLink>
            </UitkCardContentSection>
          )}
        </UitkCard>
      </UitkLayoutGridItem>
    </UitkLayoutGrid>
  );
};
