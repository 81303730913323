import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkBadge, UitkBadgeSize, UitkBadgeTheme } from "@egds/react-core/badge";

import { ProductTypeContext, ProductTypeDescriptionContext } from "components/flexComponents/TopCities/TopCities";
import { ProductType, ProductIconType } from "components/flexComponents/TopCities/utils/topCitiesEnums";

interface IconName {
  hotels: ProductIconType;
  flights: ProductIconType;
  cars: ProductIconType;
  vacation_rentals: ProductIconType;
  accommodations: ProductIconType;
}

interface IconDescription {
  hotels: string;
  flights: string;
  cars: string;
  vacation_rentals: string;
  accommodations: string;
}

const ProductIcon = (props: { index: number; cardType: string }) => {
  const { index, cardType } = props;
  const productType = React.useContext(ProductTypeContext);
  const productTypeDescription = React.useContext(ProductTypeDescriptionContext);
  const { formatText } = useLocalization();

  const iconName: IconName = {
    hotels: ProductIconType.LOB_HOTELS,
    flights: ProductIconType.LOB_FLIGHTS,
    cars: ProductIconType.LOB_CARS,
    vacation_rentals: ProductIconType.LOB_VACATION_RENTALS,
    accommodations: ProductIconType.LOB_VACATION_RENTALS,
  };

  const iconDescription: IconDescription = {
    hotels: formatText("topCities.hotels"),
    flights: formatText("topCities.flights"),
    cars: formatText("topCities.cars"),
    vacation_rentals: formatText("topCities.vacationRentals"),
    accommodations: productTypeDescription,
  };

  if (productType === ProductType.PACKAGES) {
    return (
      <UitkLayoutPosition type="absolute" position={{ top: "two", left: "two" }}>
        <UitkLayoutFlex>
          <UitkBadge theme={UitkBadgeTheme.NOTIFICATION_2} size={UitkBadgeSize.LARGE}>
            <UitkIcon
              name={ProductIconType.LOB_FLIGHTS}
              id={`top-cities-${cardType}-${ProductType.FLIGHTS}-${index}`}
              title={iconDescription.flights}
              description={iconDescription.flights}
              size={UitkIconSize.SMALL}
            />
          </UitkBadge>

          <UitkSpacing margin={{ inlinestart: "two" }}>
            <UitkBadge theme={UitkBadgeTheme.NOTIFICATION_2} size={UitkBadgeSize.LARGE}>
              <UitkIcon
                name={ProductIconType.LOB_HOTELS}
                id={`top-cities-${cardType}-${ProductType.HOTELS}-${index}`}
                title={iconDescription.hotels}
                description={iconDescription.hotels}
                size={UitkIconSize.SMALL}
              />
            </UitkBadge>
          </UitkSpacing>
        </UitkLayoutFlex>
      </UitkLayoutPosition>
    );
  }

  return (
    <UitkLayoutPosition type="absolute" position={{ top: "two", left: "two" }}>
      <UitkBadge theme={UitkBadgeTheme.NOTIFICATION_2} size={UitkBadgeSize.LARGE}>
        <UitkIcon
          name={iconName[productType as keyof IconName]}
          id={`top-cities-${cardType}-${productType}-${index}`}
          title={iconDescription[productType as keyof IconDescription]}
          description={iconDescription[productType as keyof IconDescription]}
          size={UitkIconSize.SMALL}
        />
      </UitkBadge>
    </UitkLayoutPosition>
  );
};

export default ProductIcon;
