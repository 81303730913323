import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkImage, UitkFigure } from "@egds/react-core/images";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";

import ProductIcon from "./ProductIcon";
import ProductDateSupportText from "./ProductDateSupportText";
import ProductPriceSupportText from "./ProductPriceSupportText";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { ImageTopCardCityCardProps } from "../../../typings";

const ImageTopCardCityCard = (props: ImageTopCardCityCardProps) => {
  const { city, index, hideProductIcon } = props;
  const { name: cityName, link, deal } = city;
  const mainImage = city.images[0];
  const { formatText } = useLocalization();

  return (
    <UitkCard>
      <UitkFigure ratio={UitkFigure.AspectRatio.R16_9}>
        <UitkImage src={mainImage.url} alt={mainImage.alt} placeholderImage lazyLoading="lazy" />
        {!hideProductIcon && <ProductIcon index={index} cardType="full-bleed-image" />}
        <ProductIcon index={index} cardType="image-top-card" />
      </UitkFigure>

      <UitkCardContentSection>
        <UitkLayoutFlex direction="column">
          <UitkLayoutFlex direction="column">
            <UitkHeading size={5}>{cityName}</UitkHeading>
            <ProductDateSupportText deal={deal} />
          </UitkLayoutFlex>
          {deal && (
            <UitkLayoutFlex direction="column">
              <UitkText align="center" size={300} weight="medium" theme="emphasis">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatText("topCities.productPrice", deal.priceFormatted),
                  }}
                />
              </UitkText>
              <ProductPriceSupportText />
            </UitkLayoutFlex>
          )}
        </UitkLayoutFlex>
      </UitkCardContentSection>

      <UitkCardLink>
        <TrackedLink
          moduleName="topCities"
          rfrr="hotelTravelGuideLink"
          href={link}
          data-testid={`top-cities-image-top-card-${cityName}`}
        >
          {cityName}
        </TrackedLink>
      </UitkCardLink>
    </UitkCard>
  );
};

export default ImageTopCardCityCard;
